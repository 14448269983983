import EnhancedTable from '@/components/enhancedDisplay/enhancedTable';
import { DollarPercentageSwitch } from '@/components/form/asyncLoading/switch/mui-switch';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormTextField from '@/components/form/fields/textField';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function ItemFees() {
	const { t } = useTranslation();
	const formik = useFormikContext<any>();
	const prices = formik.values.metadata?.prices;
	
	return (
		<EnhancedTable
			data={prices}
			setData={( data ) => formik.setFieldValue( 'metadata.prices', data )}
			title={t( 'management:discount' )}
			editable={{
				onAdd: () => formik.setFieldValue( 'metadata.prices', [ ...!isEmpty( prices ) ? prices : [], {
					id       : nanoid(),
					name     : t( 'management:discount' ),
					value    : -10,
					isPercent: false,
				} ] ),
				onDelete: ( index: number ) => {
					const newPrices = [ ...prices ];
					newPrices.splice( index, 1 );
					formik.setFieldValue( 'metadata.prices', newPrices );
				},
			}}
			headers={[
				t( 'management:name' ),
				'Type',
				t( 'management:price' ),
			]}
			columns={( price, index ) => [
				<FormTextField
					key='name'
					fullWidth
					label={t( 'management:name' )}
					name={`metadata.prices.${index}.name`}
				/>,
				<FormFormControlLabel
					key='isPercent'
					sx={{ textAlign: 'center' }}
					name={`metadata.prices.${index}.isPercent`}
					label=''
					control={<DollarPercentageSwitch/>}
				/>,
				<FormTextField
					key='value'
					fullWidth
					includeNegative
					label={t( 'management:price' )}
					name={`metadata.prices.${index}.value`}
					type='number'
					format={( value ) => +value || 0}
					onFocus={( e ) => e.target.select()}
				/>,
			]}
		/>
	);
}
