import FormSelectTable from '@/components/form/fields/formSelectTable';
import PageSection from '@/components/page/section';
import { ModifierGroupsRead } from '@/data/management/modifierGroup.graphql';
import { DeepPartial } from '@/types/deepPartial';
import { Item, ModifierGroup, QueryModifierGroupsReadArgs } from '@/types/schema';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import ModifierGroupRowPanel from '../../stores/form/lineItems/modifierGroupRowPanel';

export default function ItemsModifierGroups() {
	const { t } = useTranslation();
	const formik = useFormikContext<Item>();
	
	if ( !formik.values.modifierGroups?.length ) return null;
	
	return (
		<PageSection primary='Modifier Group'>
			<FormSelectTable<ModifierGroup, QueryModifierGroupsReadArgs, DeepPartial<ModifierGroup>>
				disableCloseOnSelect
				hideSelector
				name='modifierGroups'
				textFieldProps={{ placeholder: t( 'common:search-by-name' ) }}
				queryKey='modifierGroups'
				query={ModifierGroupsRead}
				blurOnSelect={false}
				getOptionLabel={( option: ModifierGroup ) => option.name}
				checkDuplicate={( item, newValue: ModifierGroup ) => item?.id === newValue?.id}
				tableProps={{
					headers    : [ 'Name' ],
					columns    : ( item ) => [ item.name ],
					renderPanel: ( modifierGroup: ModifierGroup ) => <ModifierGroupRowPanel row={modifierGroup}/>,
					hideAddIcon: true,
					hideDelete : ( item ) => true,
				}}
			/>
		</PageSection>
	);
}
