import FormSelectTable from '@/components/form/fields/formSelectTable';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { queryGraphQL } from '@/data/apollo';
import { ClientsRead } from '@/data/management/client.graphql';
import CategorySelect from '@/pages/formSelects/categorySelect';
import { CategoriesReadForMSRP } from '@/pages/formSelects/selectsGQL';
import useUserInfo from '@/providers/auth/useUserInfo';
import { DeepPartial } from '@/types/deepPartial';
import { Category, Client, Item, Msrp, QueryCategoriesReadArgs, QueryClientsReadArgs } from '@/types/schema';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { differenceWith, isEmpty } from 'lodash-es';
import { nanoid } from 'nanoid';
import React from 'react';

export default function Msrps() {
	const formik = useFormikContext<Item & { clientCategories: Category[] }>();
	const { staff } = useUserInfo();
	
	return (
		<PageSection primary='Special Prices'>
			<Grid container>
				<Grid item lg={6} xs={12}>
					<CategorySelect
						fullWidth
						multiple
						label='Select Clients by Categories'
						type='CLIENT'
						name='clientCategories'
						onAdd={undefined}
						onChange={async ( e, value: Category[] ) => {
							let savedMsrps: Msrp[] = formik.values.msrps;
							
							// remove msrps with deleted client categories
							const deletedClientCategories = differenceWith( formik.values.clientCategories || [], value, ( a: Category,
								b: Category ) => a.id === b.id );
							
							if ( !isEmpty( deletedClientCategories ) ) {
								const deletedClients = deletedClientCategories.map( ( { clients } ) => clients ).flat();
								savedMsrps = differenceWith( savedMsrps || [], deletedClients, ( a: Msrp,
									b: Client ) => a.client?.id === b.id );
								await formik.setFieldValue( 'msrps', savedMsrps );
							}
							
							// add msrps with client categories
							if ( value?.length ) {
								const { categoriesRead } = await queryGraphQL<QueryCategoriesReadArgs>( {
									query    : CategoriesReadForMSRP,
									variables: { options: { filter: { id: { $in: value.map( ( { id } ) => id ) } } } },
								} );
								
								const categoryClients = categoriesRead.items.map( ( { clients } ) => clients ).flat();
								
								const clientsToAdd = differenceWith( categoryClients, savedMsrps, ( a: Client,
									b: Msrp ) => a.id === b.client?.id );
								if ( !isEmpty( clientsToAdd ) ) {
									const msrps = clientsToAdd.map( ( client ) => ( {
										id   : nanoid(),
										value: formik.values.uoms?.find( ( uom ) => uom.selected )?.price
											|| formik.values.uoms?.[ 0 ]?.price || 0,
										client,
										staff,
									} ) );
									await formik.setFieldValue( 'msrps', [ ...savedMsrps || [], ...msrps ] );
								}
							}
						}}
					/>
				</Grid>
			</Grid>
			<Typography sx={{ fontWeight: 'bold' }}>Search Clients</Typography>
			<FormSelectTable<Client, QueryClientsReadArgs, DeepPartial<Msrp>>
				disableCloseOnSelect
				name='msrps'
				textFieldProps={{ placeholder: 'Search By Name, Contact, Email..' }}
				queryKey={[ 'clients' ]}
				query={ClientsRead}
				blurOnSelect={false}
				variables={{
					options: {
						limit : 50,
						filter: { isHidden: null },
					},
				}}
				selectedOption={( client,
					msrps ) => Boolean( msrps?.find( ( msrp ) => msrp.client?.id === client.id ) )}
				getOptionLabel={( { contact, name, email, phone, gateway }: Client ) => [
					name,
					contact,
					email ? ` - ${email}` : '',
					phone,
					gateway ? ` - ${gateway.externalId}` : '',
				].join( ' ' ).trim()}
				tableProps={{
					hideAddIcon: true,
					headers    : [ 'Name', 'Price' ],
					columns    : ( msrp, index ) => [
						msrp.client?.name || msrp.client?.contact || '-',
						<FormTextField
							key='value'
							type='number'
							name={`msrps.${index}.value`}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								inputProps    : { min: 0 },
							}}
							format={( value ) => Math.abs( +value ) || 0}
							onFocus={( event ) => event.target.select()}
						/>,
					],
				}}
				onChange={async ( event, value: Client ) => {
					if ( value ) {
						if ( formik.values.msrps?.find( ( msrp ) => msrp.client?.id === value.id ) ) {
							await formik.setFieldValue( 'msrps', formik.values.msrps?.filter( ( msrp ) => msrp.client?.id !== value.id ) );
						} else {
							await formik.setFieldValue( 'msrps', [ ...formik.values.msrps || [], {
								id   : nanoid(),
								value: formik.values.uoms?.find( ( uom ) => uom.selected )?.price
									|| formik.values.uoms?.[ 0 ]?.price || 0,
								client: value,
								staff,
							} ] );
						}
					}
				}}
			/>
		</PageSection>
	);
}
