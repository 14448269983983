import { DollarPercentageSwitch } from '@/components/form/asyncLoading/switch/mui-switch';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormSelectTable from '@/components/form/fields/formSelectTable';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { PricesRead } from '@/data/management/price.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import useAccountType from '@/helpers/useAccountType';
import PostCloverTaxModal from '@/pages/dashboard/settings/doc/taxesAndFees/postCloverTaxModal';
import LocationSelect from '@/pages/formSelects/locationSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { DeepPartial } from '@/types/deepPartial';
import { Item, Location, Price, QueryPricesReadArgs } from '@/types/schema';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

export default function ItemsTaxes() {
	const { staff } = useUserInfo();
	const formik = useFormikContext<Item & { taxLocation: Location }>();
	const { t } = useTranslation();
	const { showModal } = useModal();
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	return (
		<PageSection
			primary='Taxes'
			primaryTypographyProps={{ variant: 'h6' }}>
			<Stack spacing={1}>
				{isCloverAccount && (
					<Stack spacing={1}>
						<LocationSelect
							onlyClover
							removeCreate
							name='taxLocation'
						/>
					</Stack>
				)}
				<FormSelectTable<Price, QueryPricesReadArgs, DeepPartial<Price>>
					name='taxes'
					label='Taxes'
					title={t( 'management:taxes' )}
					query={PricesRead}
					queryKey={[ 'prices' ]}
					hideSelector={!isCloverAccount || !formik.values.taxLocation}
					getOptionLabel={( tax: Price ) => `${tax.name}  (${tax.isPercent
						? `${tax.value}%`
						: currencyFormat( tax.value )})`}
					checkDuplicate={( item, newValue: Price ) => item?.id === newValue?.id}
					variables={{
						options: {
							limit : 40,
							filter: {
								company   : staff?.company?.id,
								name      : { $nin: [ 'Tax (auto)', 'NO_TAX_APPLIED', 'NO_TAXAPPLIED' ] },
								externalId: { $ne: null },
								payment   : null,
								gateway   : formik.values.taxLocation?.gateway?.id || undefined,
							},
						},
					}}
					tableProps={{
						extraData: isCloverAccount,
						hover    : false,
						headers  : [ t( 'management:name' ), t( 'management:value' ) ],
						columns  : ( tax, index ) => [
							isCloverAccount
								? <Typography key='name'>{tax.name}</Typography>
								: (
									<FormTextField key='name' fullWidth name={`taxes[${index}].name`}>
										{tax.name}
									</FormTextField>
								), isCloverAccount
								? (
									<Typography key='name'>
										{tax.isPercent ? `${tax.value}%` : currencyFormat( tax.value )}
									</Typography>
								)
								: (
									<FormTextField
										fullWidth
										name={`taxes[${index}].value`}
										type='number'
										size='small'
										sx={{
											'.MuiFormControlLabel-root': {
												':hover': { bgcolor: 'transparent' },
											},
										}}
										format={( value ) => +value < 0 ? 0 : +value}
										InputProps={{
											inputProps    : { min: 0 },
											startAdornment: <Typography pr={1}>{tax.isPercent ? '%' : '$'}</Typography>,
											endAdornment  : (
												<FormFormControlLabel
													name={`taxes[${index}].isPercent`}
													disabled={tax?.value < 0 && tax.value !== null}
													label=''
													control={<DollarPercentageSwitch size='small'/>}
												/>
											),
										}}
										onFocus={( { target } ) => target.select()}
									/>
								),
						],
						editable: {
							onAdd: async () => {
								if ( isCloverAccount ) {
									showModal( PostCloverTaxModal, { maxWidth: 'sm' }, {
										cloverLocation: formik.values.taxLocation,
										onSubmit      : async ( price: Price ) => {
											await formik.setFieldValue( 'taxes', [ ...formik.values.taxes || [], price ] );
										},
									} );
								} else {
									const newTax = {
										id       : v4(),
										name     : 'Tax',
										value    : 10,
										isPercent: true,
										metadata : null,
									};
									await formik.setFieldValue( 'taxes', [ ...formik.values.taxes || [], newTax ] );
								}
								
							},
							onDelete: async ( price ) => await formik.setFieldValue( 'taxes', formik.values.taxes?.filter( ( row ) => row.id !== price.id ) || [] ),
						},
					}}
					onChange={async ( e, newTax ) => {
						if ( newTax ) {
							await formik.setFieldValue( 'taxes', [ ...formik.values.taxes || [], newTax ] );
						}
					}}
				/>
			</Stack>
		</PageSection>
	);
}

