import { useModal } from '@/providers/modal';
import { Delete as DeleteIcon, Upload as UploadIcon } from '@mui/icons-material';
import type { ButtonGroupProps, CardMediaProps } from '@mui/material';
import { Button, ButtonGroup, Card, CardMedia } from '@mui/material';
import { useField } from 'formik';
import { useMemo, useState } from 'react';
import type { ReactCropperProps } from 'react-cropper';
import ImageUploadCropper from '../../fileUploading/imageUploadCropper';

export default function FormImage( {
	name,
	buttonName,
	buttonProps,
	disabled,
	defaultImage,
	children,
	cropperProps,
	...props
}: {
	name: string,
	buttonName?: string,
	buttonProps?: ButtonGroupProps,
	disabled?: boolean,
	defaultImage?: string,
	cropperProps?: ReactCropperProps
} & CardMediaProps ) {
	const [ , { value = null }, { setValue, setTouched } ] = useField( name );
	const { showModal } = useModal();
	const [ original, setOriginal ] = useState<Blob | File | string>();
	
	const imageSrc = useMemo( () => typeof value === 'string' || !value
		? value as string || defaultImage
		: URL.createObjectURL( value ), [ value ] );
	
	const UploadButton = (
		<ButtonGroup
			sx={{
				display : disabled ? 'none' : undefined,
				position: imageSrc && 'absolute',
				right   : ( theme ) => imageSrc && theme.spacing( 2 ),
				bottom  : ( theme ) => imageSrc && theme.spacing( 2 ),
			}}
			variant={imageSrc ? 'contained' : 'outlined'}
			color='primary'
			{...buttonProps}>
			<Button
				startIcon={<UploadIcon/>}
				onClick={() => {
					showModal( ImageUploadCropper, undefined, {
						initialFile: original || value,
						onSave     : async ( blob, original ) => {
							setOriginal( original );
							setTouched( true );
							setValue( blob );
						},
						...cropperProps,
					} );
				}}>
				Upload new Image
			</Button>
			{value && (
				<Button
					color='error'
					startIcon={<DeleteIcon/>}
					onClick={() => setValue( null )}>
					Delete Image
				</Button>
			)}
		</ButtonGroup>
	);
	
	if ( !imageSrc ) return UploadButton;
	
	return (
		<Card sx={{ position: 'relative' }}>
			<CardMedia image={imageSrc} {...props}>
				{imageSrc ? null : children}
			</CardMedia>
			{UploadButton}
		</Card>
	);
}
