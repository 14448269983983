import { CollapseWithGrid } from '@/components/customCollapse';
import FormAvatar from '@/components/form/fields/avatar';
import FormSelect from '@/components/form/fields/select';
import FormSwitch from '@/components/form/fields/switch';
import FormTextField from '@/components/form/fields/textField';
import { ListItemComp } from '@/components/listItem';
import PageSection from '@/components/page/section';
import UomLinks from '@/pages/dashboard/management/items/form/uomLinks';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import { Item, Uom, UomLink } from '@/types/schema';

import {
	CancelRounded as CancelRoundedIcon,
	ExpandMore as ExpandMoreIcon,
	Inbox as InboxIcon,
} from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Checkbox,
	Collapse,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	ListItem,
	ListItemText,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { findLastIndex, isEmpty, partition } from 'lodash-es';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { v4 as uuidv4 } from 'uuid';
import VendorSelect from '../../../../formSelects/vendorSelect';

export default function Uoms() {
	const { t } = useTranslation();
	const formik = useFormikContext<Item & {
		uomLinks: UomLink[],
		deletedUomLinkIds: string[],
		fetchedUomLinks: boolean,
		initialUomLinks: UomLink[]
	}>();
	
	const viewItemCosts = checkViewAllPermission( 'itemCosts' );
	const [ , { value }, { setValue } ] = useField<Partial<Uom[]>>( 'uoms' );
	const [ newIndex, setNewIndex ] = useState( -1 );
	
	const [ removedUoms, uoms ] = partition( value, ( uom: Uom ) => uom.removed );
	const sortedUoms = [ ...removedUoms, ...uoms ];
	const removedUomIndex = findLastIndex( sortedUoms, ( o: Uom ) => o.removed ?? false );
	
	return (
		<PageSection
			boxProps={{ sx: { my: 2 } }}
			primary='UOMS'
			actions={[ {
				name   : 'Add UOM',
				onClick: () => {
					setNewIndex( uoms.length );
					const markupTotal = formik.values.markupPrices?.reduce( ( total, price ) => total + price.value, 0 );
					
					setValue( [ ...removedUoms, ...uoms, {
						id       : uuidv4(),
						name     : 'Unit',
						price    : 0,
						cost     : 0,
						vendorSku: '',
						code     : '',
						markup   : markupTotal || 0,
						selected : !!isEmpty( value ),
					} ] as Partial<Uom[]> );
				},
			} ]}>
			<Grid
				container
				sx={{
					'.MuiAccordionDetails-root': { px: 4, py: 2 },
					'.MuiAccordion-root'       : {
						'px'                : 0,
						'bgcolor'           : 'transparent',
						'borderRadius'      : '0 !important',
						'boxShadow'         : 'none',
						'border'            : 0,
						':before'           : { display: 'none' },
						'borderColor'       : 'divider',
						'&:not(:last-child)': { borderBottom: '2px !important' },
						'&:before'          : { display: 'none' },
					},
				}}>
				<Grid item xs={12}>
					<ListItemComp
						primary={t( 'management:inventory-item' )}
						secondary='Is this item an inventory item?'
						primaryTypographyProps={{ fontWeight: 'bold' }}
						rightComp={( <FormSwitch name='isInventory'/> )}
						listItemProps={{ disableGutters: true }}
					/>
				</Grid>
				<CollapseWithGrid in={Boolean( formik.values.isInventory )}>
					<Grid item xs={6} mb={2}>
						<FormSelect
							fullWidth
							name='type'
							label={t( 'management:inventory-type' )}
							onChange={async ( e ) => {
								await formik.setFieldValue( 'type', e.target.value === 'None' ? null : e.target.value );
							}}>
							<MenuItem value='None'>{t( 'management:none' )}</MenuItem>
							<MenuItem value='RAW_MATERIALS'>{t( 'management:raw-materials' )}</MenuItem>
							<MenuItem value='WORK_IN_PROCESS'>{t( 'management:work-in-process' )}</MenuItem>
							<MenuItem value='FINISHED_GOODS'>{t( 'management:finished-goods' )}</MenuItem>
						</FormSelect>
					</Grid>
				</CollapseWithGrid>
				<Grid
					item
					xs={12}
					className='commerceAccordion'
					sx={{ borderRadius: 2, bgcolor: 'background.default' }}>
					<TransitionGroup>
						{uoms.length ? uoms.map( ( uom: Uom, index ) => {
							const modifiedIndex = index + ( removedUomIndex + 1 );
							return (
								<Collapse key={index} timeout={500}>
									<Fragment>
										<Accordion
											key={index}
											disableGutters
											square
											defaultExpanded
											expanded={newIndex === index || uoms.length === 1}
											onChange={() => setNewIndex( newIndex === index ? -1 : index )}>
											<AccordionSummary expandIcon={uoms.length > 1 && <ExpandMoreIcon/>}>
												<Tooltip
													title={uom.selected
														? t( 'management:default-uom' )
														: t( 'management:set-as-default-uom' )}>
													<Box className='center'>
														<Checkbox
															checked={Boolean( uom.selected )}
															onClick={( e ) => {
																formik.setFieldTouched( 'uoms' );
																e.stopPropagation();
															}}
															onChange={( e, selected ) => {
																if ( sortedUoms.filter( ( sortedUom ) => sortedUom?.selected )?.length === 1 && !selected ) return;
																setValue( sortedUoms.map( ( u ) => {
																	if ( u?.id === uom.id ) {
																		return { ...u, selected: true };
																	} else {
																		return { ...u, selected: false };
																	}
																} ) as Partial<Uom[]> );
															}}
														/>
													</Box>
												</Tooltip>
												<ListItem sx={{ pl: 3, py: 0 }}>
													<ListItemText
														primaryTypographyProps={{ fontSize: '15px !important' }}
														primary={uom.name}
													/>
													{uoms.length > 1 && (
														<Tooltip title={t( 'common:delete' )}>
															<IconButton
																onClick={async ( e ) => {
																	e.stopPropagation();
																	const newData = [ ...uoms ];
																	newData.splice( index, 1 );
																	setValue( [ ...removedUoms, ...newData ] );
																	await formik.setFieldValue( 'uomLinks', formik.values.uomLinks?.filter( ( uomLink ) => uomLink.sourceUom.id !== uom.id ) );
																	
																}}>
																<CancelRoundedIcon color='error'/>
															</IconButton>
														</Tooltip>
													)}
												</ListItem>
											</AccordionSummary>
											<AccordionDetails>
												<Grid item container spacing={1}>
													<Grid item xs={12}>
														<FormAvatar
															name={`uoms.${modifiedIndex}.image`}
															variant='rounded'
															sx={{ width: 50, height: 50, img: { objectFit: 'contain' } }}
															iconButtonSX={{ p: .05 }}>
															<InboxIcon sx={{ color: 'white' }}/>
														</FormAvatar>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={`UOM ${index + 1}`}
															name={`uoms.${modifiedIndex}.name`}
															onFocus={( event ) => event.target.select()}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'common:price' )}
															type='number'
															name={`uoms.${modifiedIndex}.price`}
															InputProps={{
																startAdornment: <InputAdornment position='start'>$</InputAdornment>,
																inputProps    : { min: 0 },
															}}
															format={( value ) => Math.abs( +value ) || 0}
															onFocus={( event ) => event.target.select()}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={(
																<Tooltip title={'Manufacturer\'s Suggested Retail Price'}>
																	<Typography>
																		{t( 'management:msrp' )}
																	</Typography>
																</Tooltip>
															)}
															value={String( uom.msrp ?? '' )}
															name={`uoms.${modifiedIndex}.msrp`}
															InputProps={{
																startAdornment: <InputAdornment position='start'>$</InputAdornment>,
																inputProps    : { min: 0 },
															}}
															type='number'
															format={( value ) => Math.abs( +value ) || 0}
															onFocus={( event ) => event.target.select()}
														/>
													</Grid>
													{viewItemCosts && (
														<Grid item xs={12} sm={4}>
															<FormTextField
																key='cost'
																fullWidth
																label={t( 'management:cost' )}
																type='number'
																name={`uoms.${modifiedIndex}.cost`}
																InputProps={{
																	startAdornment: <InputAdornment position='start'>$</InputAdornment>,
																	inputProps    : { min: 0 },
																}}
																format={( value ) => Math.abs( +value ) || 0}
																onFocus={( event ) => event.target.select()}
																onChange={( e ) => {
																	const cost = +e.target.value;
																	const markUp = uom.markup || 0;
																	formik.setFieldValue( `uoms.${modifiedIndex}.cost`, cost );
																	if ( markUp > 0 ) {
																		formik.setFieldValue( `uoms.${modifiedIndex}.price`, cost * markUp / 100 + cost );
																	}
																}}
															/>
														</Grid>
													)}
													{viewItemCosts && (
														<Grid item xs={12} sm={4}>
															<FormTextField
																fullWidth
																name={`uoms.${modifiedIndex}.markup`}
																InputProps={{
																	startAdornment: <InputAdornment position='start'>%</InputAdornment>,
																	inputProps    : { min: 0 },
																}}
																label={t( 'common:markup' )}
																placeholder='Margin'
																type='number'
																format={( value ) => Math.abs( +value ) || 0}
																onFocus={( e ) => e.target.select()}
																onChange={( e ) => {
																	const cost = uom?.cost || 0;
																	const markupTotal = +e.target.value;
																	formik.setFieldValue( `uoms.${modifiedIndex}.markup`, markupTotal );
																	formik.setFieldValue( `uoms.${modifiedIndex}.price`, cost > 0 && markupTotal > 0
																		? cost * markupTotal / 100 + cost
																		: uom.price );
																}}
															/>
														</Grid>
													)}
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'common:code' )}
															name={`uoms.${modifiedIndex}.code`}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'management:sku' )}
															name={`uoms.${modifiedIndex}.sku`}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'management:vendor-sku' )}
															name={`uoms.${modifiedIndex}.vendorSku`}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'management:barcode' )}
															name={`uoms.${modifiedIndex}.barCode`}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															key='min'
															fullWidth
															label={t( 'management:min' )}
															type='number'
															name={`uoms.${modifiedIndex}.min`}
															format={( value ) => Math.abs( +value ) || 0}
															onFocus={( event ) => event.target.select()}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<Stack alignItems='end' direction='row' spacing={1}>
															<FormTextField
																fullWidth
																label={t( 'management:in-stock' )}
																value={String( uom.quantity ?? '' )}
																name={`uoms.${modifiedIndex}.quantity`}
																type='number'
																format={( value ) => value ? value : 0}
																onFocus={( event ) => event.target.select()}
															/>
														</Stack>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															key='max'
															fullWidth
															label={t( 'management:max' )}
															type='number'
															name={`uoms.${modifiedIndex}.max`}
															format={( value ) => Math.abs( +value ) || 0}
															onFocus={( event ) => event.target.select()}
														/>
													</Grid>
													<Grid item xs={12} sm={4}>
														<FormTextField
															fullWidth
															label={t( 'management:aisle' )}
															name={`uoms.${modifiedIndex}.aisle`}
														/>
													</Grid>
													<Grid item xs={12}>
														<VendorSelect
															multiple
															variables={{ options: { filter: { active: true } } }}
															name={`uoms.${modifiedIndex}.menus`}
														/>
													</Grid>
													<Grid item xs={12}>
														<UomLinks uom={uom}/>
													</Grid>
												</Grid>
											</AccordionDetails>
										</Accordion>
										{index !== uoms.length - 1 && <Divider/>}
									</Fragment>
								</Collapse>
							);
						} ) : (
							<Typography sx={{ textAlign: 'center', my: 2 }}>
								{t( 'management:no-unit-of-measurement' )}
							</Typography>
						)}
					</TransitionGroup>
				</Grid>
			</Grid>
		</PageSection>
	);
}
