import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { PricesRead } from '@/data/management/price.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { Price, QueryPricesReadArgs } from '@/types/schema';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const getOptionDisplay = ( option: Price ) => {
	const value = Math.abs( option.value );
	const typeLabel = option.isPercent ? '%' : '$';
	const discountLabel = option.value < 0 ? ' Discount' : '';
	return `${option.name} (${typeLabel}${value}${discountLabel})`;
};

export default function PriceSelect( props: Omit<FormGraphQLAutoCompleteProps<Price, QueryPricesReadArgs>, 'query' | 'queryKey'> ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	
	return (
		<FormGraphQLAutocomplete<Price>
			queryKey={[ 'prices' ]}
			query={PricesRead}
			variables={{ company: staff.company.id }}
			getOptionLabel={getOptionDisplay}
			renderOption={( props, option ) => (
				<MenuItem {...props} key={option.id ?? ''}>
					{getOptionDisplay( option )}
				</MenuItem>
			)}
			{...props}
			textFieldProps={{ label: props.label || t( 'commerce:saved-fees' ), ...props.textFieldProps }}
		/>
	);
}
