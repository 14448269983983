import Form from '@/components/form';
import { DollarPercentageSwitch } from '@/components/form/asyncLoading/switch/mui-switch';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import { mutateGraphQL } from '@/data/apollo';
import { PriceWrite } from '@/data/management/price.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Location, MutationPriceWriteArgs, Price } from '@/types/schema';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Collapse, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LocationSelect from '../../../../formSelects/locationSelect';

type CloverTaxType = {
	location: Location | null,
	name: string,
	value: number,
	isPercent: boolean
};

export default function PostCloverTaxModal( { onSubmit, cloverLocation }: {
	onSubmit: ( price: Price ) => void,
	cloverLocation?: Location
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const companyId = staff?.company.id;
	
	return (
		<Form<CloverTaxType>
			initialValues={{ location: cloverLocation || null, name: '', value: 0, isPercent: true }}
			onSubmit={async ( values ) => {
				try {
					// post tax to clover
					const { data } = await axios.post( '/api/processor/manage/postTaxRates', {
						gatewayId: values.location?.gateway.id,
						price    : {
							name     : values.name,
							value    : values.value,
							isPercent: values.isPercent,
						},
					} );
					
					const { priceWrite } = data ? await mutateGraphQL<MutationPriceWriteArgs>( {
						mutation : PriceWrite,
						variables: {
							input: {
								company   : companyId,
								name      : values.name,
								value     : values.value,
								isPercent : values.isPercent,
								externalId: data.id,
								gateway   : values.location?.gateway.id,
								metadata  : { isDefault: data?.isDefault, externalTax: true },
							},
						},
					} ) : null;
					
					onSubmit( priceWrite );
					
				} catch {
					throw new Error( 'Error creating the tax on Clover. Please try again later.' );
				}
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title={t( 'common:clover-create-item-tax' )}
					saveButtonProps={{
						disabled : !formik.values.name || formik.values.value < 0 && formik.values.value !== null,
						startIcon: <ReceiptIcon/>,
					}}>
					<Stack spacing={1}>
						<LocationSelect
							onlyClover
							name='location'
							label={t( 'common:sync-to-clover-title' )}
							onAdd={undefined}
						/>
						<Collapse in={formik.values.location}>
							<Stack direction='row' alignItems='center' spacing={1}>
								<FormTextField
									fullWidth
									name='name'
									label={t( 'common:name' )}
									onFocus={( { target } ) => target.select()}
								/>
								<FormTextField
									fullWidth
									name='value'
									type='number'
									size='small'
									label={t( 'settings:value' )}
									sx={{
										'.MuiFormControlLabel-root': {
											':hover': { bgcolor: 'transparent' },
											'p'     : 0,
											'mr'    : 0,
										},
									}}
									format={( value ) => +value < 0 ? 0 : +value}
									InputProps={{
										inputProps    : { min: 0 },
										startAdornment: <Typography pr={1}>{formik.values.isPercent ? '%' : '$'}</Typography>,
										endAdornment  : (
											<FormFormControlLabel
												name='isPercent'
												disabled={formik.values.value < 0 && formik.values.value !== null}
												label=''
												control={<DollarPercentageSwitch size='small'/>}
											/>
										),
									}}
									onFocus={( { target } ) => target.select()}
								/>
							</Stack>
						</Collapse>
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
