import InputStepper from '@/components/form/fields/InputStepper';
import currencyFormat from '@/helpers/currencyFormat';
import type { ModifierGroup } from '@/types/schema';
import { List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function ModifierGroupRowPanel( { row, editQuantity }: { row: ModifierGroup, editQuantity?: boolean } ) {
	const { t } = useTranslation();
	
	return (
		<List
			component={Paper}
			sx={{
				width    : '100%',
				maxHeight: 400,
				overflow : 'overlay',
				border   : 0,
			}}>
			{!isEmpty( row.modifiers ) ? row.modifiers?.map( ( modifier, index ) => (
				<ListItem
					key={modifier.id}
					sx={{
						'p'                                 : .5,
						':not(.MuiListItem-root:last-child)': {
							borderBottom: 1,
							borderColor : 'divider',
						},
					}}>
					<ListItemText
						secondaryTypographyProps={{ component: 'div' }}
						primaryTypographyProps={{ component: 'div' }}
						secondary={(
							<Typography>
								{`${modifier.name} ${modifier.isPercent
									? `(${modifier.value}%)`
									: ` - ${currencyFormat( modifier.value )}`}`}
							</Typography>
						)}
					/>
					{editQuantity && (
						<ListItemText
							primaryTypographyProps={{ component: 'div' }}
							secondaryTypographyProps={{ component: 'div' }}
							secondary={(
								<InputStepper
									name={`modifiers[${index}].quantity`}
									minVal={1}
									width={200}
									onFocus={( e ) => e.target?.select()}
								/>
							)}
						/>
					)}
				</ListItem>
			) ) : <Typography sx={{ color: 'text.secondary' }}>{t( 'management:no-modifiers' )}</Typography>}
		</List>
	);
}
