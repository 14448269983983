import FormImage from '@/components/form/fields/image';
import FormSwitch from '@/components/form/fields/switch';
import FormTextField from '@/components/form/fields/textField';
import { ListItemComp } from '@/components/listItem';
import PageSection from '@/components/page/section';
import useAccountType from '@/helpers/useAccountType';
import { checkViewAllPermission } from '@/pages/dashboard/management/teams/permissionsUtil';
import CategorySelect from '@/pages/formSelects/categorySelect';
import LocationSelect from '@/pages/formSelects/locationSelect';
import PriceSelect from '@/pages/formSelects/priceSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Item, Price } from '@/types/schema';
import { Grid, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash-es';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function ItemFormDetails() {
	const formik = useFormikContext<Item>();
	const { staff } = useUserInfo();
	const ref = useRef<HTMLDivElement>( null );
	const { t } = useTranslation();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const viewItemCosts = checkViewAllPermission( 'itemCosts' );
	
	const descriptionLength = formik.values.description?.length || 0;
	
	return (
		<PageSection primary={t( 'management:details' )}>
			<Grid ref={ref} container spacing={2}>
				<Grid item xs={12}>
					<FormImage
						cropperProps={{ aspectRatio: 0 }}
						name='image'
						buttonName={t( 'common:add-item-image' )}
						sx={{
							width         : '100%',
							height        : ref?.current?.clientWidth / 16 * 9,
							backgroundSize: 'contain',
							display       : 'flex',
							alignItems    : 'center',
							justifyContent: 'center',
						}}
					/>
				</Grid>
				<Grid container item xs={12} spacing={2}>
					<Grid item xs={12} sm={6}>
						<FormTextField
							fullWidth
							name='name'
							label={t( 'management:name' )}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							multiline
							fullWidth
							name='description'
							label={t( 'management:description' )}
							minRows={3}
							error={descriptionLength > 255}
							inputProps={{ maxLength: 300 }}
							helperText={descriptionLength > 255
								? t( 'management:long-description-field' )
								: !isEmpty( formik.values.description )
									? t( 'management:field-length', { field: descriptionLength } )
									: undefined}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CategorySelect
							multiple
							fullWidth
							name='categories'
							variables={{
								options: {
									limit : 50,
									filter: {
										type     : 'ITEM',
										locations: formik.values?.locations?.map( ( loc ) => loc.id ) || undefined,
									},
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<LocationSelect
							fullWidth
							multiple
							name='locations'
							onChange={async ( e, value ) => {
								// if location changes, make sure to fetch that Clover locations categories only
								if ( isCloverAccount || formik.values.externalId ) {
									await formik.setFieldValue( 'categories', [] );
								}
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							fullWidth
							name='glcode'
							label='GL Code'
						/>
					</Grid>
					{viewItemCosts && (
						<Grid item xs={12} sm={6}>
							<PriceSelect
								multiple
								fullWidth
								name='markupPrices'
								label={t( 'management:mark-up-prices' )}
								value={formik.values.markupPrices || []}
								variables={{
									options: {
										filter: {
											company : staff?.company?.id,
											isMarkup: true,
										},
									},
								}}
								getOptionLabel={( { name, value }: Price ) => `${name} ${value}%`}
								renderOption={( props, option ) => (
									<MenuItem {...props} key={option.id ?? ''}>
										{option.name} {option.value}%
									</MenuItem>
								)}
								onChange={( e, markupPrices: Price[] ) => {
									formik.setFieldValue( 'markupPrices', markupPrices );
									if ( !isEmpty( formik.values.uoms ) ) {
										const markupTotal = markupPrices.reduce( ( total, price ) => total + price.value, 0 );
										formik.setFieldValue( 'uoms', formik.values.uoms.map( ( uom ) => ( {
											...uom,
											markup: markupTotal,
											price : uom.cost > 0 && markupTotal > 0
												? uom.cost * markupTotal / 100 + uom.cost
												: uom.price,
										} ) ) );
									}
								}}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<FormTextField
							multiline
							fullWidth
							name='note'
							placeholder={t( 'common:add-note' )}
							label='Note'
							variant='outlined'
							inputProps={{ maxLength: 1500 }}
							rows={5}
						/>
					</Grid>
					{!formik.values?.externalId && (
						<Grid item xs={12}>
							<ListItemComp
								primary={t( 'management:force-stock' )}
								secondary={t( 'management:force-stock-desc' )}
								primaryTypographyProps={{ fontWeight: 'bold' }}
								rightComp={( <FormSwitch name='removeUom'/> )}
								listItemProps={{ disableGutters: true }}
							/>
						</Grid>
					)}
					<Grid item xs={12}>
						<ListItemComp
							primary={t( 'management:taxable' )}
							secondary='This item is taxable'
							primaryTypographyProps={{ fontWeight: 'bold' }}
							rightComp={( <FormSwitch name='taxable'/> )}
							listItemProps={{ disableGutters: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ListItemComp
							primary='Exclude From Sales'
							secondary={formik.values.noCommission
								? 'This item is excluded from the sales report.'
								: 'Exclude this item from the sales report.'}
							primaryTypographyProps={{ fontWeight: 'bold' }}
							rightComp={( <FormSwitch name='noCommission'/> )}
							listItemProps={{ disableGutters: true }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</PageSection>
	);
}
