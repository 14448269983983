import PageSection from '@/components/page/section';
import SelectTable from '@/components/selectTable';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Box, Divider, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { partition } from 'lodash-es';
import { nanoid } from 'nanoid';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { UomsRead } from '../../../../../data/management/uom.graphql';
import currencyFormat from '../../../../../helpers/currencyFormat';
import { DeepPartial } from '../../../../../types/deepPartial';
import { Item, QueryUomsReadArgs, Uom, UomLink } from '../../../../../types/schema';

export default function UomLinks( { uom }: { uom: Uom } ) {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const formik = useFormikContext<Item & { uomLinks: UomLink[] }>();
	const disableRecipe = formik.values.externalId;
	const [ currentUomLinks, otherUomLinks ] = partition( formik.values.uomLinks, ( uomLink ) => uomLink.sourceUom?.id === uom.id );
	
	if ( disableRecipe ) return null;
	
	return (
		<PageSection primary='Recipe' secondary="Update the stock levels of other items when this item's stock changes.">
			<Box sx={{ '.MuiTableContainer-root .MuiTable-root': { minWidth: '500px !important' } }}>
				<SelectTable<Uom, QueryUomsReadArgs, DeepPartial<UomLink>>
					disableCloseOnSelect
					data={currentUomLinks}
					setData={( uomLinks ) => formik.setFieldValue( 'uomLinks', [ ...otherUomLinks, ...uomLinks ] )}
					queryKey={[ 'uoms' ]}
					blurOnSelect={false}
					query={UomsRead}
					textFieldProps={{ placeholder: t( 'common:search-name-unit' ) }}
					variables={{
						options: {
							filter: {
								selected: true,
								item    : { company: staff?.company?.id, externalId: null },
							},
						},
					}}
					getOptionLabel={( {
						name,
						sku,
						cost,
						vendorSku,
						item,
						code,
						quantity,
					}: Uom ) => `${name} ${item.name} ${sku} ${code} ${quantity} ${cost} ${vendorSku} ${item.description
						? `(${item.description})`
						: ''}`}
					renderOption={( props, option ) =>
						(
							<Fragment key={option.id ?? ''}>
								<MenuItem
									selected={Boolean( currentUomLinks?.find( ( uomLink ) => uomLink?.linkedUom?.id === option.id ) )}
									{...props}>
									<Stack sx={{ width: '100%' }}>
										<Grid container spacing={1}>
											<Grid item xs={8}>
												<Typography fontWeight={500}>
													{option.item.name}
												</Typography>
											</Grid>
											<Grid item xs={4}>
												<Typography fontWeight={500} sx={{ textAlign: 'right' }}>
													Cost: {currencyFormat( option.cost ) || '$0.00'} ({option.name})
												</Typography>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={1}
											sx={{ '.MuiTypography-root': { fontSize: { xs: 11, sm: 13 } } }}>
											<Grid item xs={4}>
												{option.code && (
													<Typography color='text.secondary'>
														Code: {option.code}
													</Typography>
												)}
											</Grid>
											<Grid item xs={4}>
												{option.sku ? (
													<Typography sx={{ textAlign: 'center' }} color='text.secondary'>
														SKU: {option.sku || ''}
													</Typography>
												) : ''}
											</Grid>
											<Grid item xs={4}>
												{option.quantity ? (
													<Typography sx={{ textAlign: 'right' }}>
														{option.quantity} available
													</Typography>
												) : ''}
											</Grid>
											{option.item.description ? (
												<Grid item xs={12}>
													<Typography fontWeight={500}>
														{option.item.description.length > 100
															? option.item.description.slice( 0, 100 ) + '...'
															: option.item.description}
													</Typography>
												</Grid>
											) : ''}
										</Grid>
									</Stack>
								</MenuItem>
								<Divider sx={{ my: 1 }}/>
							</Fragment>
						)}
					tableProps={{
						hideAddIcon: true,
						headers    : [ t( 'common:name' ), t( 'common:unit' ), t( 'commerce:update-stock' ) ],
						columns    : ( uomLink ) => [
							uomLink.linkedUom?.item?.name,
							uomLink.linkedUom?.name,
							<TextField
								key={uomLink.id}
								type='number'
								value={currentUomLinks?.find( ( savedUomLink ) => savedUomLink.id === uomLink.id )?.quantity || 0}
								InputProps={{
									inputProps: { min: 0 },
								}}
								onFocus={( event ) => event.target.select()}
								onChange={async ( event ) => {
									const quantity = Math.abs( +event.target.value );
									await formik.setFieldValue( 'uomLinks', [ ...otherUomLinks,
									                                          ...currentUomLinks.map( ( savedUomLink ) => {
										                                          if ( savedUomLink?.linkedUom?.id === uomLink?.linkedUom?.id ) {
											                                          return { ...savedUomLink, quantity };
										                                          }
										                                          return savedUomLink;
									                                          } ) ] );
								}}
							/>,
						],
					}}
					onChange={async ( event, value: Uom ) => {
						if ( value ) {
							const savedUomLink = currentUomLinks?.find( ( uomLink ) => uomLink?.linkedUom?.id === value.id );
							if ( savedUomLink?.id ) {
								await formik.setFieldValue( 'uomLinks', [ ...otherUomLinks,
								                                          ...currentUomLinks?.filter( ( uomLink ) => uomLink?.linkedUom?.id !== value.id ) || [] ] );
								
							} else {
								await formik.setFieldValue( 'uomLinks', [ ...formik.values.uomLinks || [], {
									id       : nanoid(),
									quantity : 1,
									linkedUom: value,
									sourceUom: uom,
								} ] );
							}
						}
					}}
				/>
			</Box>
		</PageSection>
	);
}

