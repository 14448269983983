import { ActionProps } from '@/components/actions';
import CallEffect from '@/components/callEffect';
import Loading from '@/components/loading';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { ItemRead, ItemWrite } from '@/data/management/item.graphql';
import FormGraphqlProvider from '@/data/query/formGraphqlProvider';
import uploadFile from '@/data/uploadFile';
import idPick from '@/helpers/idPick';
import sequenceMap from '@/helpers/sequenceMap';
import useAccountType from '@/helpers/useAccountType';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import { getNextCustomNumber } from '@/pages/dashboard/commerce/components/tableHelpers';
import ItemsTaxes from '@/pages/dashboard/management/items/form/taxes';
import { useCloverLocations } from '@/pages/formSelects/locationSelect';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	Category,
	Item,
	Location,
	MutationItemWriteArgs,
	MutationPricesWriteArgs,
	Staff,
	UomLink,
} from '@/types/schema';
import { gql } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import { differenceWith, isEmpty, isNumber, pick } from 'lodash-es';
import React, { ComponentType, Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { syncItemToClover, uomsLinkedToUomLink } from '../itemUtils';
import ItemFormDetails from './details';
import ItemFees from './fees';
import ItemsModifierGroups from './modifierGroups';
import Msrps from './msrps';
import Uoms from './uoms';

function useItemValidationSchema() {
	const { t } = useTranslation();
	return yup.object().shape( {
		name: yup
			.string()
			.nullable().optional()
			.required( t( 'common:enter-item-name' ) )
			.max( 200, t( 'common:item-name-long' ) ),
		description: yup
			.string()
			.nullable().optional()
			.max( 255, t( 'management:item-description-is-too-long' ) ),
		uoms: yup
			.array()
			.of( yup
				.object()
				.shape( {
					name: yup
						.string()
						.required( t( 'common:uom-name' ) )
						.max( 64, t( 'common:uom-long' ) ),
					price: yup
						.number()
						.required( t( 'common:enter-price' ) )
						.lessThan( 10000000000, t( 'management:price-exceeded' ) ),
					vendorSku: yup
						.string()
						.nullable().optional()
						.max( 64, t( 'management:vendor-sku-is-too-long' ) ),
					min: yup
						.number()
						.nullable().optional()
						.when( 'max', ( max, schema ) => max
							? schema.max( max, t( 'management:min-must-be-less-than-max' ) )
							: schema ),
				} ),
			),
		addresses: yup
			.array()
			.min( 1, t( 'management:must-select-an-address' ) ),
	} );
}

export const getNewItemLocations = ( staff: Staff, allLocations: Location[] ) => staff?.locations?.length > 0
	? staff.locations
	: allLocations;

export default function ItemForm( {
	id,
	onSubmit,
	Wrapper = Fragment,
}: {
	id?: string,
	onSubmit?: ( item: Item ) => void,
	Wrapper?: ComponentType<{
		name: string,
		children: ReactNode,
		moreActions?: ActionProps[],
		saveButtonText?: string
	}>
} ) {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { staff } = useUserInfo();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const confirmDialog = useConfirmDialog();
	const validationSchema = useItemValidationSchema();
	const [ allLocations, loading ] = useCloverLocations( Boolean( id ), { options: { limit: 20 } } );
	const newLocations = getNewItemLocations( staff!, allLocations );
	const customSku = staff?.company.metadata?.customSKU || '';
	const nextCustomSku = getNextCustomNumber( customSku );
	if ( loading ) return <Loading/>;
	
	return (
		<CheckPermissions redirect='/dashboard/management/items' permissions={permissions.items.write}>
			<FormGraphqlProvider<Item & {
				clientCategories: Category[],
				uomLinks: UomLink[],
				fetchedUomLinks: boolean,
				initialUomLinks: UomLink[]
			}>
				id={id}
				queryKey='item'
				query={ItemRead}
				initialValues={() => ( {
					uoms: [ {
						id       : uuidv4(),
						selected : true,
						name     : 'Unit',
						price    : 0,
						cost     : 0,
						sku      : nextCustomSku || undefined,
						vendorSku: '',
					} ],
					isInventory: true,
					type       : 'FINISHED_GOODS',
					taxable    : true,
					locations  : newLocations,
				} )}
				validationSchema={validationSchema}
				onSubmit={async ( { id, ...values } ) => {
					try {
						
						const oneCloverLocation = values.locations?.length === 1 && values.locations?.[ 0 ]?.gateway?.external === 'CLOVER';
						const uomLinks = await uomsLinkedToUomLink( values.uoms?.map( ( uom ) => uom.id ) );
						const sourceItemName = uomLinks?.find( ( uomLink ) => uomLink.sourceUom?.item?.name )?.sourceUom?.item?.name;
						if ( oneCloverLocation && !values.externalId && !isEmpty( uomLinks ) ) {
							
							await confirmDialog( {
								title     : 'Item As Recipe',
								buttonText: 'OK',
								message   : `This item is added as a recipe  ${sourceItemName
									? `to "${sourceItemName}"`
									: ''}.\n Please remove it from the recipe before syncing this item to Clover.`,
							} );
							return;
						}
						
						// if not Clover account, then we allow editing the item taxes
						if ( !isCloverAccount && !isEmpty( values.taxes ) ) {
							await mutateGraphQL<MutationPricesWriteArgs>( {
								mutation: gql`mutation ItemTaxesWrite($ids: [String!], $input: PriceValidator, $inputs: [PriceValidator!], $options: FilterOptions, $remove: Boolean) {
													pricesWrite(ids: $ids, input: $input, inputs: $inputs, options: $options, remove: $remove)
								}`,
								variables: {
									inputs: values.taxes?.map( ( tax ) =>
										idPick( tax, [ 'name', 'value', 'isPercent' ] ),
									),
								},
							} );
						}
						
						const uomHasCustomSKU = values.uoms?.some( ( uom ) => uom.sku === nextCustomSku );
						const { itemWrite } = await mutateGraphQL<MutationItemWriteArgs>( {
							mutation : ItemWrite,
							variables: {
								id,
								method   : id ? 'Saved Changes' : 'New Item',
								customSKU: Boolean( !id && uomHasCustomSKU && nextCustomSku ),
								input    : {
									...pick( values, [
										'name',
										'note',
										'description',
										'glcode',
										'taxable',
										'isInventory',
										'removeUom',
										'noCommission',
									] ),
									image       : values.image ? await uploadFile( values.image ) : undefined,
									markupPrices: values.markupPrices?.map( ( { id } ) => id ) || [],
									locations   : values.locations?.map( ( { id } ) => id )?.filter( Boolean ) || [],
									categories  : values.categories?.map( ( { id } ) => id )?.filter( Boolean ) || [],
									type        : values.type || null,
									metadata    : values.metadata,
									taxes       : values.taxes?.map( ( { id } ) => id )?.filter( Boolean ) || [],
									uoms        : await Promise.all( sequenceMap( values.uoms, async ( uom ) => ( {
										...idPick( uom, [
											'selected',
											'name',
											'price',
											'vendorSku',
											'cost',
											'code',
											'barCode',
											'markup',
											'removed',
											'aisle',
										] ),
										sku     : uom.sku,
										min     : uom.min,
										max     : uom.max,
										msrp    : uom.msrp,
										menus   : uom.menus?.map( ( { id } ) => id )?.filter( Boolean ) || [],
										quantity: uom.quantity && isNumber( +uom.quantity ) ? +uom.quantity : null,
										image   : uom.image ? await uploadFile( uom.image ) : undefined,
									} ) ) ),
									msrps: values.msrps?.filter( ( msrp ) => msrp.client )?.map( ( msrp ) => ( {
										...idPick( msrp, [ 'value' ] ),
										client : msrp.client?.id || null,
										staff  : msrp.staff?.id || staff?.id,
										company: values.company?.id || staff?.company.id,
									} ) ),
									modifierGroups: values.modifierGroups?.map( ( modifierGroup ) => modifierGroup.id ),
								},
							},
						} );
						
						// adding and deleting uomLinks
						if ( ( !isEmpty( values?.initialUomLinks ) || !isEmpty( values?.uomLinks ) ) && !values.externalId ) {
							
							const uomLinksToBeDeleted = differenceWith( values?.initialUomLinks, values?.uomLinks, ( a,
								b ) => a.id === b.id );
							
							await mutateGraphQL( {
								mutation: gql`mutation UomLinksItemForm( $inputs: [UomLinkValidator!]!, $deletedIds: [String!]) {
											uomLinksBatchWrite( inputs: $inputs, deletedIds: $deletedIds)
						}`,
								variables: {
									deletedIds: uomLinksToBeDeleted?.map( ( uomLink ) => uomLink.id ),
									inputs    : values?.uomLinks?.map( ( uomLink ) => ( {
										...idPick( uomLink, [ 'quantity' ] ),
										sourceUom: uomLink.sourceUom?.id,
										linkedUom: uomLink.linkedUom?.id,
									} ) ),
								},
							} );
						}
						
						onSubmit?.( itemWrite );
						// auto sync to clover if item has externalId or one Clover location
						if ( values.externalId || oneCloverLocation ) {
							await syncItemToClover( { itemId: itemWrite.id } );
						}
						
						await queryClient.invalidateQueries( [ 'company' ] );
						await queryClient.invalidateQueries( [ 'user' ] );
						
					} catch ( e ) {
						throw e;
					}
				}}>
				{( formik ) => {
					const oneCloverLocation = formik.values.locations?.length === 1 && formik.values.locations?.[ 0 ]?.gateway?.external === 'CLOVER';
					
					return (
						<Wrapper
							name='Item'
							saveButtonText={Boolean( formik.values.externalId ) || oneCloverLocation && isEmpty( formik.values.uomLinks )
								? t( 'common:sync-save' )
								: undefined}>
							<CallEffect
								func={async () => {
									if ( formik.values.id && formik.values.uoms?.length && !formik.values.fetchedUomLinks ) {
										const uomIds = formik.values.uoms.map( ( uom ) => uom.id );
										const { uomLinksRead } = await queryGraphQL( {
											query: gql`query UomLinksByUom($options: FilterOptions) {
													uomLinksRead(options: $options) {
														items {
															id
															quantity
															sourceUom {
																id
																name
																item {
																	name
																}
																	}
															linkedUom {
																id
																name
																item {
																	name
																}
																	}
															}}
																		}`,
											variables: {
												options: {
													filter: {
														sourceUom: { id: { $in: uomIds } },
													},
												},
											},
											
										} );
										await formik.setFieldValue( 'fetchedUomLinks', true );
										await formik.setFieldValue( 'uomLinks', uomLinksRead?.items || [] );
										await formik.setFieldValue( 'initialUomLinks', uomLinksRead?.items || [] );
									}
									
								}}
								deps={[ formik.values.id ]}
							/>
							<ItemFormDetails/>
							<ItemsTaxes/>
							<Uoms/>
							<Msrps/>
							<ItemsModifierGroups/>
							<ItemFees/>
						</Wrapper>
					);
				}}
			</FormGraphqlProvider>
		</CheckPermissions>
	);
}
